import { OktaAuth } from '@okta/okta-auth-js';
import { StateService } from '@uirouter/core';
import rewritehandoff, { RewriteHandoffService } from '../rewritehandoff/rewritehandoff.service';
import uiRouter from '@uirouter/angularjs';

const authClient = new OktaAuth({
    issuer: process.env.OKTA_SERVER_URL,
    clientId: process.env.OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/login`,
    scopes: ['openid', 'email', 'profile'],
});

export class OktaService {
    signingIn = false;
    constructor(
        private $http: any, // Custom get config
        private $location: ng.ILocationService,
        private $log: ng.ILogService,
        private $window: ng.IWindowService,
        private $state: StateService,
        private $rootScope: ng.IRootScopeService,
        private rewritehandoff: RewriteHandoffService,
    ) {}
    async handleRedirect() {
        if (authClient.isLoginRedirect() && process.env.AUTH_PROVIDER === 'OKTA') {
            const { tokens } = await authClient.token.parseFromUrl();
            authClient.tokenManager.setTokens(tokens);
            await this.signIn();
            return true;
        }
        return false;
    }
    async signIn() {
        if (!(await authClient.isAuthenticated())) {
            authClient.token.getWithRedirect();
            return;
        }
        const {
            accessToken: { accessToken },
        } = await authClient.tokenManager.getTokens();

        try {
            const data = await this.$http.post(
                `${process.env.API_URL}/user/authenticate`,
                {
                    data: {
                        type: 'authenticate',
                        attributes: {
                            access_token: accessToken,
                            provider: 'okta',
                        },
                    },
                },
                {
                    headers: {
                        Accept: 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                    },
                    skipAuthorization: true,
                },
            );
            this.$window.localStorage.setItem('token', data.data.data.attributes.json_web_token);
            const redirect = angular.copy(this.$window.localStorage.getItem('redirect'));
            const params = angular.copy(JSON.parse(this.$window.localStorage.getItem('params')) || {});
            this.$window.localStorage.removeItem('redirect');
            this.$window.localStorage.removeItem('params');
            if (redirect) {
                this.$location.search(params);
                this.$location.path(redirect);
            } else {
                this.$state.go('home', params, { reload: true });
            }
            this.$rootScope.$apply();
        } catch (err) {
            await this.signOut();
            this.$state.go('logout');
            this.$rootScope.$apply();
            throw err;
        }
    }
    async signOut() {
        await authClient.signOut();
        if (this.rewritehandoff.hasLoggedintoRewriteApp()) {
            this.$window.location.href = `${this.rewritehandoff.newOrigin}/logout`;
        }
        return true;
    }
}

export default angular.module('mpdx.services.okta', [uiRouter, rewritehandoff]).service('okta', OktaService).name;
